<template>
  <index-content>
    <template v-if="hasRW" #options>
      <nb-setting @on-click="onBtnSetting" />
      <nb-reset @on-ok="onBtnReset" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <n-field-selection
        v-model="condition.appName"
        selection-name="system.app.name"
        label="appName"
        all-option
        @on-change="onAppNameChange"
      />
      <n-field-select v-model="condition.appId" :options="appIds" label="appId" all-option />
      <n-field-datetime v-model="condition.from" label="from" />
      <n-field-datetime v-model="condition.to" label="to" />
      <n-field-enum v-model="condition.monitorLevel" enum-name="MonitorLevel" label="level" all-option />
    </search-condition>
    <n-table
      :total="total"
      :page-no="pageNo"
      :page-size="pageSize"
      :searched="searched"
      :searching="searching"
      :has-result="hasResult"
      @on-page-no="onPageNo"
      @on-page-size="onPageSize"
    >
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="n-table-timestamp">{{ $t('label.time') }}</th>
            <th class="col-weight-2">{{ $t('label.app') }}</th>
            <th class="col-weight-2">{{ $t('label.policy') }}</th>
            <th class="col-weight-3">{{ $t('label.monitorName') }}</th>
            <th class="col-weight-2">{{ $t('label.monitorKey') }}</th>
            <th class="col-weight-2">{{ $t('label.property') }}</th>
            <th class="col-weight-2">{{ $t('label.comment') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.timestamp | datetime }}</td>
            <td>{{ row.appName }}:{{ row.appId }}</td>
            <td v-enums:MonitorPolicy="row.monitorPolicy"></td>
            <td class="n-ellipsis" :title="row.monitorName || ''">{{ row.monitorName | hyphen }}</td>
            <td class="n-ellipsis" :title="row.monitorKey || ''">{{ row.monitorKey | hyphen }}</td>
            <td class="n-ellipsis" :title="row.property || ''">{{ row.property | hyphen }}</td>
            <td class="n-ellipsis n-text-right" :title="row.comment || ''">{{ row.comment | hyphen }}</td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import SystemView from './view';
import { mapMutations } from 'vuex';
import { getSelection } from '@/store';
import { size, get, sortBy } from 'lodash-es';
import PaginateMixin from '@/mixins/paginate-mixin';
import { fetch, reset } from '@/api/system/system-alert';

export default SystemView.extend({
  name: 'SystemAlert',
  mixins: [PaginateMixin],

  data() {
    return {
      condition: {
        appId: 0,
        appName: 0,
        monitorLevel: 0,
        from: this.oneWeekAgo,
        to: this.eod,
      },
    };
  },

  computed: {
    appIds() {
      let ret = [];
      const { appName } = this.condition;
      if (size(appName) > 0) {
        const selection = getSelection('system.app.id', appName);
        sortBy(get(selection, ['extra', appName], [])).forEach(value => {
          ret.push({ name: value, value });
        });
      }
      return ret;
    },
  },

  methods: {
    ...mapMutations('access', ['pushMenu']),

    doSearch(params) {
      return fetch({
        ...params,
        ...this.condition,
      });
    },

    onAppNameChange() {
      this.condition.appId = 0;
    },

    onBtnReset() {
      return reset();
    },

    onBtnSetting() {
      this.pushMenu({ url: '/system/alert/setting' });
    },
  },
});
</script>
<style lang="scss" scoped>
.n-table {
  min-width: 876px;
}
</style>
